import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Skeleton } from "@mui/material";
import { PetsOutlined } from "@mui/icons-material";
import { FHCurrency } from "../FHCurrency";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import classNames from "classnames";

const useStyles = (bookingImageUrl?: string) =>
  makeStyles((theme) => ({
    root: {
      padding: theme.spacing(1.5, 2),
      backgroundColor: bookingImageUrl
        ? "transparent"
        : theme.palette.primary.main,
      position: "relative",
      minHeight: 150,
      "&:after": {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        content: '""',
        position: "absolute",
        zIndex: 2,
        backgroundImage: !!bookingImageUrl
          ? `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`
          : "none",
      },
    },
    backgroundImg: {
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      zIndex: 1,
      "& >span": {
        position: "static !important",
      },
    },
    overlayContent: {
      position: "relative",
      zIndex: 3,
    },
    bookingInfoPriceBubble: {
      textAlign: "right",
      borderRadius: theme.shape.borderRadius * 3 + 1,
      padding: theme.spacing(0.5, 1),
      background: `${theme.palette.common.black}b3`,
    },
    cabinSellingPrice: {
      display: "inline",
      color: theme.palette.common.white,
      fontSize: 15,
      lineHeight: "20px",
    },
    bookingInfoText: {
      color: theme.palette.common.white,
      lineHeight: "18px",
    },
    itemsOnTheRight: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    petIcon: {
      paddingLeft: theme.spacing(1),
      verticalAlign: "middle",
      marginBottom: theme.spacing(0.5),
    },
    preDiscountCabinSellingPrice: {
      display: "inline",
      color: theme.palette.action.active,
      fontSize: 15,
      lineHeight: "20px",
      textDecoration: "line-through",
      marginRight: theme.spacing(0.5),
    },
    bookingReference: {
      color: theme.palette.common.black,
      fontSize: 12,
      marginTop: "auto",
    },
    moveBookingInfoRoot: {
      padding: theme.spacing(1.5, 2),
    },
    moveBookingInfoTextBlack: {
      "& p": { color: `${theme.palette.text.primary} !important` },
    },
    moveBookingInfoBookingReference: {
      color: theme.palette.text.primary,
    },
    increaseImageHeight: {
      minHeight: 150,
    },
  }))();

type BookingInfoProps = {
  bedroomLabel?: string;
  bookingImageUrl?: string;
  cabinSellingPrice?: number;
  cabinType?: string;
  endDate?: string;
  loading?: Boolean;
  locationName?: string;
  numberOfBedrooms?: string;
  petFriendly?: boolean;
  preDiscountCabinSellingPrice?: number;
  specificCabinLabel?: string;
  specificCabinNumber?: string;
  startDate?: string;
  imageLoader?: ImageLoader;
  bookingReference?: string;
  bookingReferenceLabel?: string;
  increaseHeightImageCheckout?: boolean;
};

export const BookingInfo: React.FC<PropsWithChildren<BookingInfoProps>> = ({
  bedroomLabel = "bedroom",
  bookingImageUrl,
  cabinSellingPrice = 0,
  cabinType,
  endDate,
  loading = false,
  locationName,
  numberOfBedrooms,
  petFriendly = false,
  preDiscountCabinSellingPrice,
  specificCabinLabel = "Cabin",
  specificCabinNumber,
  startDate,
  imageLoader,
  bookingReference,
  bookingReferenceLabel,
  increaseHeightImageCheckout,
}: PropsWithChildren<BookingInfoProps>) => {
  const classes = useStyles(bookingImageUrl);

  return (
    <>
      <Grid
        item
        xs={12}
        className={classNames(classes.root, {
          [classes.increaseImageHeight]: increaseHeightImageCheckout,
        })}
      >
        {loading ? (
          <>
            <Skeleton height={18} />
            <Skeleton height={18} />
            <Skeleton height={18} />
            <Skeleton height={18} />
          </>
        ) : (
          <>
            {bookingImageUrl && (
              <div className={classes.backgroundImg}>
                <FHNextImage
                  src={bookingImageUrl}
                  blurDataURL={bookingImageUrl}
                  alt={locationName}
                  loading="lazy"
                  loader={imageLoader}
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            )}
          </>
        )}
      </Grid>
      <Grid className={classes.moveBookingInfoRoot}>
        <Grid className={classes.overlayContent} container xs={12}>
          <Grid item xs className={classes.moveBookingInfoTextBlack}>
            {!!startDate && !!endDate && (
              <Typography variant={"body2"} className={classes.bookingInfoText}>
                <strong>
                  {startDate} - {endDate}
                </strong>
              </Typography>
            )}
            {!!locationName && (
              <Typography variant={"body2"} className={classes.bookingInfoText}>
                <strong>{locationName}</strong>
              </Typography>
            )}
            {!!cabinType && (
              <Typography variant={"body2"} className={classes.bookingInfoText}>
                {cabinType}
                {petFriendly && <PetsOutlined className={classes.petIcon} />}
              </Typography>
            )}
            {!!numberOfBedrooms && (
              <Typography variant={"body2"} className={classes.bookingInfoText}>
                {`${numberOfBedrooms} ${bedroomLabel}`}
                {!!specificCabinNumber &&
                  ` (${specificCabinLabel} #${specificCabinNumber})`}
              </Typography>
            )}
          </Grid>
          <Grid item xs className={classes.itemsOnTheRight}>
            {cabinSellingPrice > 0 && (
              <div className={classes.bookingInfoPriceBubble}>
                {!!preDiscountCabinSellingPrice && (
                  <Typography className={classes.preDiscountCabinSellingPrice}>
                    <strong>
                      <FHCurrency>{preDiscountCabinSellingPrice}</FHCurrency>
                    </strong>
                  </Typography>
                )}
                <Typography className={classes.cabinSellingPrice}>
                  <strong>
                    <FHCurrency>{cabinSellingPrice}</FHCurrency>
                  </strong>
                </Typography>
              </div>
            )}
            <Typography className={classes.bookingReference}>
              {bookingReferenceLabel} {bookingReference}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
