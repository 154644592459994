import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = (showRemove: boolean, imageUrl?: string) =>
  makeStyles((theme) => ({
    container: {
      background: !!imageUrl
        ? showRemove
          ? `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${imageUrl})`
          : `url(${imageUrl})`
        : showRemove
        ? theme.palette.text.disabled
        : "none",
      width: 50,
      height: 50,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      marginBottom: theme.spacing(1),
    },
    removeButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.divider,
      border: "2px solid",
      borderRadius: theme.shape.borderRadius * 2,
      minWidth: "auto",
      padding: theme.spacing(0.2),
      margin: theme.spacing(0.9, 0, 0, 0.9),
    },
  }))();

type BasketItemImageProps = {
  imageUrl?: string;
  handleRemove?: () => void;
};

export const BasketItemImage: React.FC<BasketItemImageProps> = ({
  imageUrl,
  handleRemove,
}: PropsWithChildren<BasketItemImageProps>) => {
  const classes = useStyles(!!handleRemove, imageUrl);

  return (
    <div className={classes.container}>
      {!!handleRemove && (
        <Button
          variant="outlined"
          className={classes.removeButton}
          onClick={handleRemove}
        >
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};
