import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FHClose from "../FHClose";

const useStyles = makeStyles((theme) => ({
  backDropRoot: {
    backgroundColor: `${theme.palette.text.primary}80`,
  },
  paperRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[11],
    width: "90%",
    [theme.breakpoints.up("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  closeWrap: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5, 2),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.1, 2),
      minHeight: 54,
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "transparent",
      padding: 0,
      position: "absolute",
      right: 40,
      top: 40,
      zIndex: 100,
    },
  },
  closeButton: {
    display: "none",
    padding: theme.spacing(0.5),
    backgroundColor: `${theme.palette.text.primary}cc`,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& svg": {
      fontSize: 30,
      color: theme.palette.common.white,
    },
  },
  mobileCloseBtn: {
    marginLeft: "auto",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  headerTitle: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  subTitle: {
    fontSize: 11,
    lineHeight: "11px",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    "& button": {
      marginLeft: theme.spacing(1.5),
    },
  },
}));

type Anchors = "left" | "right";

export type FHDrawerProps = {
  children: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
  anchor?: Anchors;
  isShowCloseButton?: boolean;
  modalSubTitle?: string;
  modalTitle?: string;
  closeBtnColor?: "primary" | "secondary";
  closeBtnIcon?: JSX.Element;
  paperHandleScroll?: (event: any) => void;
};

export const FHDrawer: React.FC<FHDrawerProps> = ({
  children,
  open,
  onClose,
  anchor = "right",
  isShowCloseButton,
  modalTitle,
  modalSubTitle,
  closeBtnColor,
  closeBtnIcon = <CloseRoundedIcon />,
  paperHandleScroll,
}: PropsWithChildren<FHDrawerProps>) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.backDropRoot,
          },
        },
      }}
      PaperProps={{
        classes: {
          root: classes.paperRoot,
        },
        onScroll: paperHandleScroll,
      }}
    >
      {isShowCloseButton && (
        <div className={classes.closeWrap}>
          {modalTitle && (
            <Typography className={classes.headerTitle} variant="h4">
              {modalTitle}
            </Typography>
          )}
          <div className={classes.actions}>
            {modalSubTitle && (
              <Typography className={classes.subTitle}>
                <strong>{modalSubTitle}</strong>
              </Typography>
            )}
            <FHClose
              className={classes.mobileCloseBtn}
              color={closeBtnColor}
              icon={closeBtnIcon}
              onCloseButtonClick={onClose}
            />
            <div onClick={onClose} className={classes.closeButton}>
              <CloseRoundedIcon />
            </div>
          </div>
        </div>
      )}
      {children}
    </Drawer>
  );
};
export default FHDrawer;
